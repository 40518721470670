<script>
import { Pie } from "vue-chartjs";
export default {
  extends: Pie,
  props: {
    labels: {
      type: Array,
      default: null,
    },
    resData: {
      type: Array,
      default: null,
    },
    background: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: [
          {
            borderWidth: 2,
            borderColor: this.border,
            backgroundColor: this.background,
            fill: false,
            data: this.resData,
          },
        ],
      },
      options: {
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    resData: function(newValue) {
      newValue;
    },
  },
};
</script>
